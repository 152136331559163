<template>
  <div class="contactUs publicTop">
    <div class="body" style="display: flex; justify-content: space-between">
      <div class="left">
        <div class="top">碧莲盛医疗集团总部</div>
        <div class="item">电话：<span>400-822-1660</span></div>
        <div class="item">
          地址：<span>北京市大兴区西红门鸿坤广场B座3号楼5层</span>
        </div>
        <div class="item">邮编：<span>100162</span></div>
        <div class="wx">微信公众号</div>
        <img :src="`${$url}/contactUs/wx.png`" alt="" class="wx-img" />
      </div>
      <div class="right">
        <div id="container" style="height: 100%; width: 100%"></div>
      </div>
    </div>
  </div>
</template>

<script>
/* global BMapGL */ // 声明 BMapGL 为全局变量
export default {
  data () {
    return {
      wow: null,
      markerArr: [
        {
          lable: '北京市大兴区西红门鸿坤广场B座3号楼5层',
          point: [116.353269, 39.796477]
        }
      ]
    }
  },
  beforeDestroy () {
    this.wow.stop()
  },
  mounted () {
    document.title = '联系我们-碧莲盛医疗'
    document.documentElement.scrollTop = 0
    this.wow = new this.$wow.WOW({ live: false })
    this.wow.init()
    const map = new BMapGL.Map('container') // 创建Map实例
    map.centerAndZoom(new BMapGL.Point(116.340302, 39.79142), 12) // 初始化地图,设置中心点坐标和地图级别
    map.enableScrollWheelZoom(true) // 开启鼠标滚轮缩放fp
    const point = [] // 存放标注点经纬信息的数组
    const marker = [] // 存放标注点对象的数组
    const label = [] // 存放提示信息窗口对象的数组
    this.markerArr.forEach((item, i) => {
      point[i] = new BMapGL.Point(item.point[0], item.point[1])
      marker[i] = new BMapGL.Marker(point[i])
      map.addOverlay(marker[i])
      // 创建文本标注
      // label[i] = new BMapGL.Label(this.markerArr[i].lable, {
      //     position: point[i], // 设置标注的地理位置
      //     offset: new BMapGL.Size(0, 0), // 设置标注的偏移量
      // });

      map.addOverlay(label[i]) // 将标注添加到地图中
    })
  }
}
</script>

<style scoped lang="scss">
.contactUs {
  background-color: #f9fafd;
}

.right {
  width: 750px;
  height: 528px;
}

.left {
  width: 429px;

  .top {
    font-size: 25px;
    color: #3b3b3b;
    padding-bottom: 37px;
    border-bottom: solid 2px #c8c8c8;
    margin-bottom: 37px;
  }

  .item {
    font-size: 16px;
    color: #707070;
    line-height: 40px;

    span {
      vertical-align: bottom;
      line-height: 40px;
      color: #3b3b3b;
    }
  }
}

.wx {
  color: #707070;
  font-size: 16px;
  margin-top: 56px;
  margin-bottom: 10px;
}

.wx-img {
  width: 140px;
  height: 140px;
}
</style>
